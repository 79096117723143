
import React, { FC,useEffect } from "react"   //https://2x.ant.design/components/alert/
import {
  Center,
  Stack,
  Switcher,
  Text,
  Box,
  Grid,
} from "../common"
import { css } from "@emotion/core"
import { Layout } from "../components/Layout"
import { Link, navigate } from "gatsby"
import { ZHelmet } from "../components/ZHelmet"
import { ImageFrame } from "../common/components/ImageFrame"

const WHY_CHOOSE_US = [
  {
    icon: "https://res.cloudinary.com/sumasoft/image/upload/v1624974611/samples/Techprocess-Logo_pcssq8.png",
    title: "Cashbee",
    content:
      "Applying for loan is simple, quick and hassle-free with Zavron Finserv",
  },
  {
    icon: "https://res.cloudinary.com/sumasoft/image/upload/v1624975220/samples/Hyperverge-Logo_luajfq.png",
    title: "Klub Works",
    content: "Our loan sanction and disbursal takes just two days’ time.",
  },

]

const partners = [
  {
    icon: "https://res.cloudinary.com/dhoiqmk4x/image/upload/v1625064561/Equifax-Logo_gmxt0s.png",
    title: "Perfios",
  },
  {
    icon: "https://res.cloudinary.com/dhoiqmk4x/image/upload/v1625064588/ICICI-Logo_ahf8o3.png",
    title: "Karza",
  },
  {
    icon: "https://res.cloudinary.com/dhoiqmk4x/image/upload/v1625064630/Perfios-Logo_d6yi3g.png",
    title: "Wquifax",
  },
  {
    icon: "https://res.cloudinary.com/dhoiqmk4x/image/upload/v1625064549/Digio-Logo_yndrci.png",
    title: "RazorPay",
  },
  {
    icon: "https://res.cloudinary.com/dhoiqmk4x/image/upload/v1625118231/Salesforce-Logo_nbwtom.png",
    title: "Cashfree",
  },
  {
    icon: "https://res.cloudinary.com/dhoiqmk4x/image/upload/v1625064613/Klub-Works-Logo_wgtyx5.png",
    title: "Hyperverge",
  },
  {
    icon: "https://res.cloudinary.com/dhoiqmk4x/image/upload/v1625064599/Karza-Logo_uyc3d6.png",
    title: "IciciBank",
  },
  {
    icon: "https://res.cloudinary.com/dhoiqmk4x/image/upload/v1625064528/Cashbee-Logo_mlckbr.png",
    title: "TechProcess",
  },
  {
    icon: "https://res.cloudinary.com/dhoiqmk4x/image/upload/v1625064644/Razorpay-Logo_xx9nbo.png",
    title: "Digio",
  },
  {
    icon: "https://res.cloudinary.com/dhoiqmk4x/image/upload/v1625064539/Cashfree-Logo_hsuoyf.png",
    title: "Salesforce",
  },
  {
    icon: "https://res.cloudinary.com/dhoiqmk4x/image/upload/v1625117695/Zavron-Finserv-Open-Banking-Image_ubbv2q.png",
    title: "aws",
  },

]



const PRODUCTS = [
  {
    num: "01",
    title: "Digital Eco-system partners",
    link: "/products/personal-loan",
    content:
      "Get loans for all your personal needs from emergencies, home renovations to planning a vacation instantly",
  },
  {
    num: "02",
    title: "Used Car Loan",
    link: "/products/used-car-loan",
    content:
      "Shortage of funds for a used car? Not a problem, we have flexible EMI options for you",
  },
  {
    num: "03",
    title: "Two-wheeler Loan",
    link: "/products/two-wheeler-loan",
    content:
      "Buying your favorite two-wheeler becomes easier with Zavron Finserv’s Vehicle Loan at attractive interest rates",
  },
  {
    num: "04",
    title: "Business Loan",
    link: "/products/business-loan",
    content:
      "Plan the next expansion of your business with our easy and flexible loan policies",
  },
]



export default function partner() {

  useEffect(()=>{
    navigate('/');
  },[])
  
  return (
    // <Layout>
    //   <ZHelmet
    //     title="Instant | Personal Loan | Business Loan | NBFC | Fintech | India"
    //     description="Instant Personal Loan and Business loan as well as Used Car Loan or Two Wheeler Loan with fast and easy approval within your reach to apply now with Zavron Finserv"
    //   />
    //   <Stack space="var(--s0)">
    //     <Landing />
    //     <Products />
    //     <WhyUs />
    //     <Digital />
    //     <LogoText />
    //   </Stack>
    // </Layout>
    <></>
  )
}

const Landing: FC = props => (
  <Box>
    <Center gutters="var(--s5)" maxWidth="var(--max-width)">
      <Switcher threshold="var(--min-width)" space="var(--s-2)" max={2}>
        {/* <Stack style={{flexGrow: 2}} space="var(--s2)"> */}
        <Stack space="var(--s-4)" style={{ paddingTop: 'var(--s2)' }}>
          <Text
            style={{ color: "black" }}
            variant="h2"
            color="light"
            css={css`
     @media screen and (max-width: 600px) {
       text-align: center;
     }
   `}
          >
            Open Banking Platform for <text style={{ color: "var(--secondary-color)" }}>Fintech</text>
          </Text>
          <Text
            variant="h4"
            style={{
              fontFamily: 'var(--body-font-family)',
              whiteSpace: 'pre-line',
              marginTop: 20
            }}
            color="light">
            <Text variant="h5" color="light">
              Innovation answers to speed up your business
              and give you an edge over rivalry, through
              simple admittance to capital and worth
              accretive information investigation.
      </Text>
            <Text variant="h5" color="light">
              With the blooming business of fintech we help
              busnesses by providing the best facilities for
              lending with the help of proven technology all
              under one roof.
      </Text>
          </Text>
        </Stack>
        {/* </Stack> */}
        <Box >
          <ImageFrame numerator={6} denominator={7}>
            <img
              src="https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto/v1625064511/AWS-Logo_tgpu5b.png"
              alt=""
            // style={{
            //   //  height:400,
            //   width:400
            // }}

            />
          </ImageFrame>
          {/* <Frame numerator={29} denominator={30}>
<div style={{ margin: `0 auto`, maxWidth: 650, padding: `0 1rem`, marginLeft:30 }}>
<img
          src="https://res.cloudinary.com/sumasoft/image/upload/w_350,h_500/v1625028813/AWS-Logo_rqmrof.png"
          alt=""
          />
    </div>
    </Frame> */}
        </Box>
      </Switcher>
    </Center>
  </Box>


)

const Products: FC = props => (
  <Box
    // style={{ margin: "-var(--s0)" }}
    css={css`
        padding-top: var(--s0) !important;
        padding-bottom: var(--s4) !important;
        background-image: url("https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_1400/v1597800020/products-background_p1q9pa.png");
      `}
  >
    <Center gutters="var(--s4)" maxWidth="var(--max-width)">
      <Stack space="var(--s4)">
        <Text
          style={{
            // textTransform: "uppercase",
            color: "white",
          }}
          variant="h2"
          css={css`
              @media screen and (max-width: 600px) {
                text-align: center;
              }
            `}
        >
          Straightforwardness | Exactness | Versatility
          </Text>

      </Stack>
      <Text variant="p" color="light" style={{ color: "white", marginBottom: -35 }}
        css={css`

            margin-top: var(--s0);
            margin-bottom: var(--s0);
            font-size: var(--h3);

          `}
      >
        A blend of technology and easy integrations and sensible collaboraton<br />
            makes availability of everything your business relies upon.
          </Text>

    </Center>
  </Box>
)


const WhyUs: FC = props => {
  return (
    <Box padding="var(--s0) 0">
      <Center gutters="var(--s2)" maxWidth="var(--max-width)">
        <Stack space="var(--s3)">
          <Text
            style={{ color: "black" }}
            variant="h2"
            color="light"
            css={css`
                @media screen and (max-width: 600px) {
                  text-align: center;
                }
              `}
          >
            Partners On-board
            </Text>
          <Grid
            style={{ textAlign: "center", marginBottom: "var(--s0)" }}
            gap="var(--s1)"
            min="250px"
          >
            <Switcher max={2} threshold="var(--min-width)" space="var(--s2)">
              <Box>

                <Box>
                  <Link to="https://aknbfc.in/TytoCash-hswyc.apk">

                    <img src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1625117708/Techprocess-Logo_dsotpv.png" alt="" />

                  </Link>

                </Box>
                <Link to="https://aknbfc.in/TytoCash-hswyc.apk">
                  <Text style={{ lineHeight: '120%', marginTop: '5px', color: "blue" }} variant="h4">
                    Download App
                </Text>
                </Link>
              </Box>
              <Stack space="var(--s-0)" style={{ flexGrow: 1.5 }}>
                <Text style={{ lineHeight: '120%', }} variant="h4">
                  TytoCash
                </Text>

              </Stack>
              <Stack space="var(--s-0)" style={{ flexGrow: 1.5 }}>
                <Text style={{ lineHeight: '120%', marginTop: '-10px' }} variant="h4">
                  [ TYTO Network Technologies Pvt. Ltd. ]
                </Text>
              </Stack>
            </Switcher>

            {/* <Switcher max={2} threshold="var(--min-width)" space="var(--s2)">
              <Box>
                <Box> <img width="155" height="155"  src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1635318352/Website/Ovik_Finance_nzprlu.png" alt="" style={{ marginBottom: 51, marginTop: 46 }} />
                </Box>
              </Box>
              <Stack space="var(--s-0)" style={{ flexGrow: 1.5 }}>
                <Text style={{ lineHeight: '120%' }} variant="h4">
                  Ovik Finance
                </Text>
              </Stack>

              <Stack space="var(--s-0)" style={{ flexGrow: 1.5 }}>
                <Text style={{ lineHeight: '120%', marginTop: '-10px' }} variant="h4">
                  [Ovik Financial Services Pvt. Ltd.]
                </Text>
              </Stack>
            </Switcher> */}
          </Grid>

        </Stack>
      </Center>
    </Box>
  )
}

const Digital: FC = props => (
  <Box padding="var(--s0) 0">
    <Center gutters="var(--s2)" maxWidth="var(--max-width)">
      <Stack space="var(--s3)">
        <Text
          style={{ color: "black" }}
          variant="h2"
          color="light"
          css={css`
           @media screen and (max-width: 600px) {
             text-align: center;
           }
         `}
        >
          Digital Eco-system partners
       </Text>
        <Box>
          <Switcher max={6} threshold="var(--min-width)" space="var(--s1)">
            <Grid
              style={{ textAlign: "left", marginBottom: "var(--s0)" }}
              gap="var(--s1)"
              min="200px"
            >
              {partners.map(detail => (
                <Stack space="var(--s1)" style={{ marginBottom: "var(--s1)" }}>
                  {/* <Link to={detail.link} target="_blank"> */}
                  <Box> <img src={`${detail.icon}`} alt="" /> </Box>
                  {/* <Text variant="h4" style={{ color: "black" }}>
              {detail.title}
            </Text> */}
                  {/* </Link> */}
                </Stack>
              ))}
            </Grid>
          </Switcher>
        </Box>

      </Stack>
    </Center>
  </Box>
)

const LogoText: FC = props => (
  <Box style={{ marginTop: 7, marginBottom: -35 }}>
    <Center gutters="var(--s2)" maxWidth="var(--max-width)">
      <Text variant="h5" style={{ fontSize: 10, alignContent: 'center', textAlign: 'center' }}>*Logos displayed here are properties of their respective owners and may be subjected to copyrights.</Text>
    </Center>
  </Box>
)